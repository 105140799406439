import { derived, get, writable } from 'svelte/store';
import { propertyStore } from 'svelte-writable-derived';

export function storeProxy(store) {
  return new Proxy(store, {
    get(target, property, receiver) {
      const $target = get(target);
      const value = Reflect.get($target, property);

      if (value instanceof Function) {
        return function (...args) {
          return value.apply(this === receiver ? $target : this, args);
        };
      }

      return value;
    },
    set(target, property, value, _receiver) {
      let returnValue;

      target.update(($target) => {
        const $newTarget = { ...$target };
        returnValue = Reflect.set($newTarget, property, value);
        return $newTarget;
      });

      return returnValue;
    },
    has(target, property) {
      return Reflect.has(get(target), property);
    },
    ownKeys(target) {
      return Reflect.ownKeys(get(target));
    },
    defineProperty(target, property, attributes) {
      let returnValue;

      target.update(($target) => {
        const $newTarget = { ...$target };
        returnValue = Reflect.defineProperty($newTarget, property, attributes);
        return $newTarget;
      });

      return returnValue;
    },
    deleteProperty(target, property) {
      let returnValue;

      if (!(property in get(target))) {
        return false;
      }

      target.update(($target) => {
        const $newTarget = { ...$target };
        returnValue = Reflect.deleteProperty($newTarget, property);
        return $newTarget;
      });

      return returnValue;
    },
    getOwnPropertyDescriptor(target, property) {
      return Object.getOwnPropertyDescriptor(get(target), property);
    },
    getPrototypeOf(target) {
      return Reflect.getPrototypeOf(get(target));
    },
    setPrototypeOf(target, prototype) {
      return Reflect.setPrototypeOf(target, prototype);
    },
    isExtensible(target) {
      return Reflect.isExtensible(get(target));
    },
    preventExtensions(target) {
      return Reflect.preventExtensions(target);
    },
    apply(target, thisArgument, argumentsList) {
      return Reflect.apply(get(target), thisArgument, argumentsList);
    },
    construct(target, argumentsList, newTarget) {
      return Reflect.construct(get(target), argumentsList, newTarget);
    }
  });
}

let tydalAttributes;
try {
  tydalAttributes = (window.Cypress ? window : window.parent).Tydal;
} catch (err){
  console.error(err);
  tydalAttributes = { ...{ common: {}, rev_config: {} }, ...window.Tydal };
}
export const tydal = writable(tydalAttributes);
export const $tydal = storeProxy(tydal);

export const revConfig = propertyStore(tydal, 'rev_config');
export const $revConfig = storeProxy(revConfig);
export const customer = propertyStore(tydal, ['common', 'customer']);
export const $customer = storeProxy(customer);
export const money_format = derived(tydal, $tydal => $tydal.common.shop.money_format || '{{amount_with_comma_separator}}$');

export const currentProductReviewData = writable();
export const $currentProductReviewData = storeProxy(currentProductReviewData);

export async function initializeStorage(api) {
  (window.Cypress ? window : window.parent).Tydal = $tydal;

  currentProductReviewData.set({
    product: $tydal.common?.product,
    first_name: get(customer)?.first_name,
    last_name: get(customer)?.last_name,
    email: get(customer)?.email,
  });

  return Promise.all([
    new Promise(function (resolve) {
      resolve();
    })
  ]);
}
