"use strict";
import MicroModal from 'micromodal';
import { buildStarsHtml, microModalDefaultConfig } from "../../util"
import { $revConfig as revConfig } from '../../rev/config/storage';
import { $tydal } from '../../rev/config/storage';

(function(){
  window.addEventListener('message',function(e){
    const msgOptions = e.data.msg_options;
		try{
      switch(e.data.msg_action){
				case 'ba.ratings.resize':
					if (!reviewDisplayListIframe) return;
					reviewDisplayListIframe.style.height = `${msgOptions.scroll_height + 50}px`;
				break;
				case 'ba.ratings.open_review':
					MicroModal.show('tydal-reviews-iframe-wrapper', microModalDefaultConfig);
					modalIframe.contentWindow.postMessage({msg_action: "ba.ratings.open_review", msg_options: {review: msgOptions.review}}, '*');
				break;
				case 'ba.ratings.write_review':
					MicroModal.show('tydal-reviews-iframe-wrapper', microModalDefaultConfig)
					modalIframe.contentWindow.postMessage({msg_action: "ba.ratings.write_review"}, '*');
				break;
				case 'ba.ratings.modal.close':
					MicroModal.close('tydal-reviews-iframe-wrapper')
          modalIframe.contentWindow.postMessage({msg_action: "ba.ratings.modal.close"}, '*');
				break;
				case "ba.ratings.open_url":
					Object.assign(document.createElement("a"), { target: "_blank", href: msg.url }).click();
					break;
			}
		} catch(e){
			console.log(e);
		}
	});

  let modalIframe;
	let reviewDisplayListIframe;
  const rootVariables = `
    :root {
      --regular-font-size: 14px;
      --error-color: red;
    }
  `;

  function applyGlobalCss(){
    var style = document.createElement('style');
		style.innerHTML = `
      #tydal-reviews-panel{width:100%;border:0px;min-height:250px;}
      .tydal-reviews-iframe-panel-wrapper {position: relative; width: 100%; height: fit-content;}
      #tydal-reviews-iframe-wrapper{background:#000000ab;display:none;top:0px;left:0px;width: 100%; height: 100%; position: fixed; z-index: 2147483647; transition: all 300ms cubic-bezier(0.87, 0, 0.13, 1); overflow-y: auto; opacity:0;}
      #tydal-reviews-iframe-wrapper.shown{display:block;opacity:1;overflow: hidden;}
      #tydal-reviews-iframe-wrapper iframe{border:0px;position:relative important;width:100% !important;min-height:100% !important;}
      .tydal-reviews-star-rating {display: flex; cursor: pointer;}
      div.tydal-star-wrapper {display: flex; align-items: center;}
      div.tydal-star-wrapper svg:not(:first-child) {margin-left: 2px;}
      .tydal-reviews-rating-count {padding-left: 5px;}`;
		document.head.appendChild(style);
  }

  function renderProductPage(){
		return new Promise(function(resolve) {
      const product = $tydal.common.product;
			if (product){
	      if (revConfig.widget_settings.display_star_rating_enabled && document.querySelector(".shopify-app-block div.tydal-reviews-star-rating, .shopify-app-block div.rivo-reviews-star-rating") == undefined){
          let productTitle;
          if (revConfig.widget_settings.display_product_stars_custom_selector){
            productTitle = document.querySelector(revConfig.widget_settings.display_product_stars_custom_selector);
          } else {
            productTitle = document.querySelector(".product__title, .product-single__title, .product-meta__title");
          }
	        try {
						productTitle.insertAdjacentHTML('afterend', `
              <div class="tydal-reviews-star-rating"
                data-product-id="${product.id}"
                data-rating="${product.review_data?.stars || 0 }"
                data-count="${product.review_data?.reviews_count || 0 }">
                <div class="tydal-reviews-rating-count"></div>
              </div>
            `);
					} catch (error) {
            console.log(error);
					}
	      }

	      if (document.querySelector(".shopify-app-block div.tydal-reviews-iframe-panel-wrapper, .shopify-app-block div.rivo-reviews-iframe-panel-wrapper") == undefined){
          const panelDisplaySelectors = ["main", "#shopify-section-product-template"];
          if (revConfig.widget_settings.display_panel_custom_selector){
            panelDisplaySelectors.push(revConfig.widget_settings.display_panel_custom_selector);
          }
	        const mainContainers = document.querySelectorAll(panelDisplaySelectors.join(","));
	        if (mainContainers.length > 0){
	          mainContainers[mainContainers.length - 1].insertAdjacentHTML('beforeend', `<div class="tydal-reviews-iframe-panel-wrapper page-width wrapper ${revConfig.widget_settings?.display_panel_custom_class}"></div>`);
	        } else {
	          const sectionContainer = document.querySelector("section.page-width");
	          if (sectionContainer){
	            sectionContainer.insertAdjacentHTML('afterend', `<div class="tydal-reviews-iframe-panel-wrapper page-width wrapper ${$tydal.rev_config.widget_settings?.display_panel_custom_class}"></div>`);
	          }
	        }
	      }
			}
			resolve();
		});
	}

  function populateStarRatings(){
		return new Promise(function(resolve) {
			const ratingContainers = document.querySelectorAll(".tydal-reviews-star-rating, .rivo-reviews-star-rating");
			for (var i = 0; i < ratingContainers.length; ++i) {
				let ratingContainer = ratingContainers[i];

        if (ratingContainer.querySelector(".tydal-star-wrapper, .rivo-star-wrapper")) {
          continue;
        }
				let ratingScore = ratingContainer.getAttribute("data-rating");
				let ratingCount = ratingContainer.getAttribute("data-count");
				if (ratingScore){
					ratingContainer.insertAdjacentHTML('afterbegin', buildStarsHtml(ratingScore));

					let ratingCountSelector = ratingContainer.querySelector(".tydal-reviews-rating-count, .rivo-reviews-rating-count");
					if(ratingCountSelector){
						ratingCountSelector.insertAdjacentHTML('afterbegin', `(${ratingCount})`);
					}
				}
			}
			resolve();
		});
	}

  function initalizeProductReviewsList(){
    return new Promise(function(resolve) {
			let el = document.querySelector('div.tydal-reviews-iframe-panel-wrapper');

      if(!el || !revConfig.widget_settings.display_storefront_enabled) {
        resolve();
        return;
      }

      let style = document.createElement('style');
      style.id = `tydal-review-display-list-styles`;
      style.innerHTML = `
      #tydal-reviews-display-list { width: 100%; height: 100%; display: contents;}
      iframe.tydal-reviews{
        position: relative;
        width: 100%;
        height: 800px;
        border: none;
        overflow: hidden;
      }`;
      document.head.appendChild(style);

      el.innerHTML=`<iframe id="tydal-reviews-panel" class="tydal-reviews" name="tydal-reviews-panel" allowfullscreen="" src="about:blank" sandbox="allow-scripts allow-same-origin"></iframe>`;

      const reviewDisplayCssOverrides = `
        .rev-write-review, #rev-load-more{border: solid 1px ${revConfig.widget_css.theme_launcher_border_color};color: ${revConfig.widget_css.theme_launcher_text_color};background: ${revConfig.widget_css.theme_launcher_bg_color};}
        .rev-write-review:hover, #rev-load-more:hover{background: ${revConfig.widget_css.theme_launcher_hover_bg_color}; color: ${revConfig.widget_css.theme_launcher_hover_text_color};}
        p.first-review-text, p.first-review-text a {color: ${revConfig.widget_css.theme_first_review_text_color || "#000"};}
        .rev-star{color: ${revConfig.widget_css.theme_star_color};}
        .summary-container .summary-text{color: ${revConfig.widget_css.theme_star_text_color};}
        #panel-container {color: ${revConfig.widget_css.theme_reviews_body_color};}
        #panel-container .review-title, #panel-container .author-name{color: ${revConfig.widget_css.theme_reviews_title_color || "inherit"};}
      `
      reviewDisplayListIframe = document.getElementById(`tydal-reviews-panel`);
      reviewDisplayListIframe.contentWindow.document.write(`
        <html lang="en">
          <head>
            <title></title>
            <style type="text/css">${rootVariables}</style>
	          <link rel="stylesheet" href="${$tydal.global_config.asset_urls.rev.widget_css}" defer>
            <script src="${$tydal.global_config.asset_urls.rev.display_js}"></script>
            <style type="text/css">${reviewDisplayCssOverrides}</style>
            <style type="text/css">${revConfig.custom_css}</style>
          </head>
          <body>
            <div class="spin-wrapper ajax-interceptor ajax-loader-out"></div>
          </body>
          <script>window._init();</script>
        </html>
      `);
      reviewDisplayListIframe.contentWindow.document.close();

      if(modalIframe) return;

			document.body.insertAdjacentHTML('beforeend', `
				<div id="tydal-reviews-iframe-wrapper">
					<iframe class="tydal-reviews-modal" allowfullscreen="" src="about:blank" sandbox="allow-scripts allow-same-origin"></iframe>
				</div>
			`);
			modalIframe = document.querySelector('#tydal-reviews-iframe-wrapper iframe')
      const modalCssOverrides = `
        .wizard-slide h3, #rev-finished h1{color: ${revConfig.widget_css.theme_title_color};}
        .rev-button{color: ${revConfig.widget_css.theme_button_text_color};background-color: ${revConfig.widget_css.theme_button_bg_color};border-color: ${revConfig.widget_css.theme_button_border_color};}
        .rev-button:hover,.rev-button:active{background-color: ${revConfig.widget_css.theme_button_hover_color};}
        .rev-star{fill: ${revConfig.widget_css.theme_star_color};}
        .subtitle{color: ${revConfig.widget_css.theme_subtext_color};}
        .rev-input{color: ${revConfig.widget_css.theme_input_text_color};border: 1px solid ${revConfig.widget_css.theme_input_border_color} !important;}
        .rev-progress{border-color: ${revConfig.widget_css.theme_progress_border_color};}
        .rev-progress .full{background-color: ${revConfig.widget_css.theme_progress_color};}
        .rev-footer{color: ${revConfig.widget_css.theme_footer_color};}
        .rev-link, .rev-footer a{color: ${revConfig.widget_css.theme_link_color};}
        .rev-star-button{background: #fff;border: solid 1px ${revConfig.widget_css.theme_rating_border_color};}
        .rate-product-stars div.review-star-container a:hover{background: ${revConfig.widget_css.theme_star_color}3b;}
        .review-star-container a:after{color: ${revConfig.widget_css.theme_star_text_color};}
      `;
			modalIframe.contentDocument.write(`
				<html lang="en">
					<head>
						<title></title>
            <style type="text/css">${rootVariables}</style>
						<link rel="stylesheet" href="${$tydal.global_config.asset_urls.rev.modal_css}" defer>
            <style type="text/css">${modalCssOverrides}</style>
            <style type="text/css">${revConfig.custom_css}</style>
            <script src="${$tydal.global_config.asset_urls.rev.modal_js}"></script>
					</head>
					<body></body>
          <script>window._init();</script>
				</html>
			`);
			modalIframe.contentDocument.close();

      resolve();
		});
  }

  function initScrollIntoReviews(){
		return new Promise(function(resolve) {
			const ratingStars = document.querySelector(".tydal-reviews-star-rating, .rivo-reviews-star-rating");
			if(ratingStars != null){
				ratingStars.addEventListener("click", function(){
					document.getElementById("tydal-reviews-panel").scrollIntoView({
						behavior: "smooth"
					});
				});
			}
			resolve();
		});
	}

  window.addEventListener("load",function(){
    const scriptTagPresent = (document.getElementsByTagName('head')[0].innerHTML.search("rev_" + window.Tydal.common.shop.id) > 0) || document.getElementById("rev-app-embed-init");
    
    if (scriptTagPresent && revConfig.widget_enabled){
      applyGlobalCss();
      renderProductPage()
				.then(populateStarRatings)
				.then(initalizeProductReviewsList)
				.then(initScrollIntoReviews);
    }
  });

})();
