import { derived, get, writable } from 'svelte/store';
import { propertyStore } from 'svelte-writable-derived';

export function storeProxy(store) {
  return new Proxy(store, {
    get(target, property, receiver) {
      const $target = get(target);
      const value = Reflect.get($target, property);

      if (value instanceof Function) {
        return function (...args) {
          return value.apply(this === receiver ? $target : this, args);
        };
      }

      return value;
    },
    set(target, property, value, _receiver) {
      let returnValue;

      target.update(($target) => {
        const $newTarget = { ...$target };
        returnValue = Reflect.set($newTarget, property, value);
        return $newTarget;
      });

      return returnValue;
    },
    has(target, property) {
      return Reflect.has(get(target), property);
    },
    ownKeys(target) {
      return Reflect.ownKeys(get(target));
    },
    defineProperty(target, property, attributes) {
      let returnValue;

      target.update(($target) => {
        const $newTarget = { ...$target };
        returnValue = Reflect.defineProperty($newTarget, property, attributes);
        return $newTarget;
      });

      return returnValue;
    },
    deleteProperty(target, property) {
      let returnValue;

      if (!(property in get(target))) {
        return false;
      }

      target.update(($target) => {
        const $newTarget = { ...$target };
        returnValue = Reflect.deleteProperty($newTarget, property);
        return $newTarget;
      });

      return returnValue;
    },
    getOwnPropertyDescriptor(target, property) {
      return Object.getOwnPropertyDescriptor(get(target), property);
    },
    getPrototypeOf(target) {
      return Reflect.getPrototypeOf(get(target));
    },
    setPrototypeOf(target, prototype) {
      return Reflect.setPrototypeOf(target, prototype);
    },
    isExtensible(target) {
      return Reflect.isExtensible(get(target));
    },
    preventExtensions(target) {
      return Reflect.preventExtensions(target);
    },
    apply(target, thisArgument, argumentsList) {
      return Reflect.apply(get(target), thisArgument, argumentsList);
    },
    construct(target, argumentsList, newTarget) {
      return Reflect.construct(get(target), argumentsList, newTarget);
    }
  });
}

let tydalAttributes;
try {
  tydalAttributes = (window.Cypress ? window : window.parent).Tydal;
} catch (err){
  console.error(err);
  tydalAttributes = window.Tydal;
}
export const tydal = writable(tydalAttributes);
export const $tydal = storeProxy(tydal);

export const loyConfig = propertyStore(tydal, 'loy_config');
export const $loyConfig = storeProxy(loyConfig);

export const money_format = derived(tydal, $tydal => $tydal.common.shop.money_format || '{{amount_with_comma_separator}}$');
export const ways_to_earn = propertyStore(loyConfig, 'ways_to_earn');
export const referral_rewards = propertyStore(loyConfig, 'referral_rewards');
export const vip_tiers = propertyStore(loyConfig, 'vip_tiers');
export const customer = propertyStore(tydal, ['common', 'customer']);
export const best_reward = writable();
export const referrals_info = writable();
export const current_vip_tier_info = writable();
export const points_purchases = writable([]);
export const rewards = writable();
export const redeem_error = writable();
export const app_container_scroll = writable({ bottom: undefined });
export const productUrl = writable();

export async function initializeStorage(api) {
  (window.Cypress ? window : window.parent).Tydal = $tydal;

  const customerId = window.customer?.id;

  // Make redeem errors only visible for 5 seconds
  let redeem_error_timeout;
  redeem_error.subscribe((val) => {
    if (val === undefined) {
      return;
    }

    if (redeem_error_timeout) {
      clearTimeout(redeem_error_timeout);
      redeem_error_timeout = undefined;
    }
    redeem_error_timeout = setTimeout(() => {
      redeem_error.set(undefined);
    }, 5_000);
  });

  return Promise.all([
    api.identify_customer(customerId).then(function (data) {
      customer.update(($customer) => { return $customer || data ? { ...$customer, ...data } : $customer; });
    }),
    api.best_reward_to_show(customerId).then(function (data) {
      best_reward.set(data);
    }),
    api.referrals(customerId).then(function (data) {
      referrals_info.set(data);
    }),
    api.customer_tier(customerId).then(function (data) {
      current_vip_tier_info.set(data);
    }),
    new Promise(function (resolve) {
      function next(page) {
        api.points_purchases(customerId, false, page).then(function (data) {
          points_purchases.update($points_purchases => [...$points_purchases, ...data.points_purchases]);
          if (data.metadata.next_page) {
            next(data.metadata.next_page);
          } else {
            resolve();
          }
        });
      }

      next(0);
    })
  ]);
}