import { $tydal } from './storage';

function _rpoints(node){
  var rpoints={};
  if(node.constructor===Array){
    node.forEach(function(el){
      var temp=_rpoints(el);
      for(var i in temp) rpoints[i]=temp[i];
    });
    return rpoints;
  }

  var arr=[node];
  var temp=node.querySelectorAll('[data-rpoint]');
  for(var i=0;i<temp.length;i++) arr.push(temp[i]);

  arr.forEach(function(el){
    rpoints[el.dataset.rpoint]=el;
    delete el.dataset.rpoint;
  });
  return rpoints;
}

export function _str2html(str){
  var container=document.createElement('div');
  container.innerHTML=str;
  var children=[];
  for(var i=0;i<container.childNodes.length;i++) children.push(container.childNodes[i]);
  if(children.length===1) return children[0];
  return children;
}

function _trim(str){
  return str.trim().replace(/\n|\t/g,'');
}

export function _render(str){
  var node=_str2html(_trim(str));
  var rpoints=_rpoints(node);
  return {node:node,rpoints:rpoints};
}

export function _truncate(str, length){
  return str.length > length ? `${str.substring(0, length)}...` : str;
}

export function _template(str,args){
  return str.replace(/\{\{([\w\d\.]*)\}\}/g,function(full,name){
    if(args[name]) return args[name];
    return full;
  });
}

export function _currency_sign(money_format){
  return money_format.replace(/{{amount}}|{{amount_with_comma_separator}}|{{amount_no_decimals}}|{{amount_with_comma_separator}}|{{amount_no_decimals_with_comma_separator}}/g,'');
}

export function _number_format(number, plus){
  var i=0;
  var str='';
  var numberToStr = '';

  // if float number, we need to split by '.'
  if (Number(number) === number && number % 1 !== 0) {
    var splitFloatDot = Math.abs(number).toFixed(2).split('.')
    numberToStr = splitFloatDot[0].split('').reverse();
    str = '.' + splitFloatDot[1]
  } else {
    numberToStr = Math.abs(number).toString().split('').reverse();
  }

  numberToStr.forEach(function(n){
    if(i==3){
      str=','+str;
      i=0;
    }
    str=n+str;
    i++;
  });

  if(number<0){
    str='-\u00a0'+str;
  } else if(plus){
    str='+\u00a0'+str;
  }

  return str;
}

export function _date_format(date){
  var d=(new Date(date)).toDateString().split(' ');

  d[2]=d[2].toString();
  if(d[2].length===1) d[2]='0'+d[2];

  return d[1]+' '+d[2]+', '+d[3];
}

export function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function getUrlParam(paramName) {
  let params = {};
  window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(str,key,value) {
    params[key] = value;
  });
  return params[paramName];
}

const SUBSCRIBER_HASH_PREFIX = "ba_msg_subscriber_";

export function updateSubscriberHash(updateHash){
  let updatedSubscriberHash = window.Tydal.visitor.subscriber_hash;
  for (let prop in updateHash) {
    updatedSubscriberHash[prop] = updateHash[prop];
  }
  const subscriberKey = SUBSCRIBER_HASH_PREFIX + window.parent.tyMet.getVisitorToken();
  localStorage.setItem(subscriberKey, JSON.stringify(updatedSubscriberHash));
}

export function getSubscriberHash(){
  const rawSubscriber = localStorage.getItem(SUBSCRIBER_HASH_PREFIX + window.parent.tyMet.getVisitorToken());
  return JSON.parse(rawSubscriber || "{}");
}

export function documentReady(callback) {
  document.readyState === "interactive" || document.readyState === "complete" ? callback() : document.addEventListener("DOMContentLoaded", callback);
}

export function buildMsgFromTemplate(template, params, to_fixed=false) {
  let msg = template;
  for (const key in params) {
    const placeholder = `{{${key}}}`;
    msg = msg.replaceAll(placeholder, to_fixed ? parseFloat(params[key]).toString() : params[key]);
  }
  return msg;
}

export function getCookie(name) {
  function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
  let match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
  return match ? match[1] : null;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function generateStarSvg(starType, starSize){
  let starColor = $tydal.rev_config.widget_css.theme_star_color;
  switch(starType) {
    case "full":
      return `<svg enable-background="new 0 0 24 24" height="${starSize}" width="${starSize}" fill="${starColor}" viewBox="3.44 3.57 17.11 16.36"><path d="m0 0h24v24h-24z" fill="none"/><path d="m0 0h24v24h-24z" fill="none"/><path d="m12 17.27 4.15 2.51c.76.46 1.69-.22 1.49-1.08l-1.1-4.72 3.67-3.18c.67-.58.31-1.68-.57-1.75l-4.83-.41-1.89-4.46c-.34-.81-1.5-.81-1.84 0l-1.89 4.45-4.83.41c-.88.07-1.24 1.17-.57 1.75l3.67 3.18-1.1 4.72c-.2.86.73 1.54 1.49 1.08z"/></svg>`;
    case "empty":
      return `<svg height="${starSize}" width="${starSize}" fill="${starColor}" viewBox="3.44 3.56 17.12 16.37"><path d="m0 0h24v24h-24z" fill="none"></path><path d="m19.65 9.04-4.84-.42-1.89-4.45c-.34-.81-1.5-.81-1.84 0l-1.89 4.46-4.83.41c-.88.07-1.24 1.17-.57 1.75l3.67 3.18-1.1 4.72c-.2.86.73 1.54 1.49 1.08l4.15-2.5 4.15 2.51c.76.46 1.69-.22 1.49-1.08l-1.1-4.73 3.67-3.18c.67-.58.32-1.68-.56-1.75zm-7.65 6.36-3.76 2.27 1-4.28-3.32-2.88 4.38-.38 1.7-4.03 1.71 4.04 4.38.38-3.32 2.88 1 4.28z"></path></svg>`;

    case "half":
      return `<svg height="${starSize}" width="${starSize}" fill="${starColor}" viewBox="3.44 3.56 17.12 16.37"><path d="M0 0h24v24H0z" fill="none"></path><path d="M19.65 9.04l-4.84-.42-1.89-4.45c-.34-.81-1.5-.81-1.84 0L9.19 8.63l-4.83.41c-.88.07-1.24 1.17-.57 1.75l3.67 3.18-1.1 4.72c-.2.86.73 1.54 1.49 1.08l4.15-2.5 4.15 2.51c.76.46 1.69-.22 1.49-1.08l-1.1-4.73 3.67-3.18c.67-.58.32-1.68-.56-1.75zM12 15.4V6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"></path></svg>`;
  }
}

export function formattedPrice(price, decimalPlaces = 0) {
  if (typeof price == "undefined" || price == null){return ""}
  if (typeof price == "string" && price.length == 0){return ""}
  var format = $tydal.global_config.base_money_format || $tydal.common.shop.money_format;
  var moneyRegex = /\{\{\s*(\w+)\s*\}\}0?/;
  function defOpt(opt,def){return typeof opt == "undefined" ? def : opt}
  var isNegative = price < 0;

  function displayDelims(n, p, t, d) {
    p = defOpt(p, 2);
    t = defOpt(t, ",");
    d = defOpt(d, ".");
    if (isNaN(n) || n == null) { return 0; }

    n = Math.abs(n);
    var parts = n.toString().split(".");
    var dollars = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1" + t);
    var price = parts[1] ? parseFloat("0." + parts[1]).toFixed(p).split(".")[1] : "";
    price = price ? d + price : "";
    return dollars + price;
  }

  var val = "";
  //todo switch back to decimals
  switch (format.match(moneyRegex)[1]) {
    case "amount":
      val = displayDelims(price, decimalPlaces);
      break;
    case "amount_no_decimals":
      val = displayDelims(price, decimalPlaces);
      break;
    case "amount_no_decimals_with_comma_separator":
      val = displayDelims(price, decimalPlaces, ".", ",");
      break
    case "amount_with_comma_separator":
      val = displayDelims(price, decimalPlaces, ".", ",");
      break;
  }
  return (isNegative ? "- " : "") + format.replace(moneyRegex, val)
}

export function loadDeferredScript(src){
  var js = document.createElement('script');
  js.src = src;
  js.defer = true;
  document.head.appendChild(js);
}

export function buildStarsHtml(rating, starSize = 14, customClass = "") {
  let roundedStars = Math.round(rating * 2) / 2;
  if (rating % 1 > 0.7) {
    roundedStars = Math.ceil(roundedStars);
  }
  const fullStars = Math.floor(roundedStars);
  const halfStars = (roundedStars % 1 === 0.5) ? 1 : 0;
  const emptyStars = Math.max(0, 5 - fullStars - halfStars);

  let starHtml = `<div class='tydal-star-wrapper ${customClass}'>`;
  for (let current = 1; current <= 5; current++) {
    if (current <= fullStars) {
      starHtml += generateStarSvg("full", starSize);
    } else if (current <= fullStars + halfStars) {
      starHtml += generateStarSvg("half", starSize);
    } else {
      starHtml += generateStarSvg("empty", starSize);
    }
  }
  return starHtml + "</div>";
}

export const isShopifyDesignMode = function(){
  return document.location.href.includes("myshopify.com/admin");
}

export const microModalDefaultConfig = {
  onShow: modal => window.parent.document.querySelector('body').style.overflow = "hidden",
  onClose: function(modal, element, event) {
    event?.preventDefault();
    event?.stopPropagation();
    window.parent.document.querySelector('body').style.overflow = "";
  },
  openClass: 'shown'
}

//Clicks, psl_actions
export const psl_actions = (function () {
  var _list = {};

  function _add(uid, callback) {
    if (typeof (uid) === 'object') {
      for (var i in uid) _add(i, uid[i]);
      return;
    }
    _list[uid] = callback;
  }

  function _remove(uid) {
    if (typeof (uid) !== 'string') {
      if (uid instanceof Object) {
        for (var i in uid) _remove(i);
        return;
      } else if (uid instanceof Array) {
        uid.forEach(_remove);
        return;
      }
    }
    delete _list[uid];
  }

  function _handler(e) {
    var el = e.target;
    while (el && el.dataset && !el.dataset.action) el = el.parentNode;
    if (!el) return;
    if (!el.dataset || !el.dataset.action) return;
    if (!_list[el.dataset.action]) return;
    e.stopPropagation();
    e.preventDefault();
    var args = el.dataset.data;
    if (args) {
      args = args.split(',').map(function (el) {
        try {
          return JSON.parse(el);
        } catch (e) {
          return el;
        }
      });
    } else {
      args = [];
    }
    args.unshift(e);

    _list[el.dataset.action].apply(undefined, args);
    return false;
  }

  function _fire(action, e) {
    if (action instanceof HTMLElement) return action.click();
    if (!_list[action]) return;
    var args = [];
    for (var i = 2; i++; i < arguments.length) args.push(arguments[i]);
    args.unshift(e);
    _list[action].apply(undefined, args);
  }

  window.addEventListener('click', _handler, true);

  var _ext = {
    add: _add,
    remove: _remove,
    fire: _fire,
  };

  return _ext;
})();

export const psl_events=(function(){

  var _evt_list={};

  var _ext={
    on:function(name, clb){
      if(typeof(name)==="object"){
        for(var i in name) _ext.on(i,name[i]);
        return;
      }
      if(_ext._has_clb(name,clb)) return;
      if(!_evt_list[name]) _evt_list[name]=[];
      _evt_list[name].push(clb);
    },
    _has_clb:function(name,clb){
      if(!_evt_list[name]) return false;
      return _evt_list[name].indexOf(clb)!==-1;
    },
    once:function(name,clb){
      if(typeof(name)==="object"){
        for(var i in name) _ext.once(i,name[i]);
        return;
      }
      clb.once=true;
      _ext.on(name,clb);
    },
    emit:function(name,args){
      if(!args) args=[];
      if(!_evt_list[name]) return;
      _evt_list[name].forEach(function(clb){
        clb.apply(undefined,args);
        if(clb.once) _ext.remove(name,clb);
      });
    },
    remove:function(name,clb){
      if(typeof(name)==="object"){
        for(var i in name) _ext.remove(i,name[i]);
        return;
      }
      if(!_evt_list[name]) return;
      var index=_evt_list[name].indexOf(clb);
      if(index===-1) return;
      _evt_list[name].splice(index,1);
    }
  }

  return _ext;
})();

export function generateUtmUrl(url, campaign, params={}) {
  const utmParams = {
    utm_source: "tydal",
    utm_medium: "other",
    utm_campaign: campaign,
    ...params
  };

  const queryString = Object.keys(utmParams)
    .map(key => key + '=' + encodeURIComponent(utmParams[key]))
    .join('&');

  return url + (url.indexOf('?') === -1 ? '?' : '&') + queryString;
}

export function copyToClipboardAndFocus(el, {copyClass = "copy", successClass = "loy-copy-success"} = {}) {
  try{
    navigator.clipboard.writeText(el.value);

    var trigged_btn = el.nextElementSibling;
    trigged_btn.classList.remove(copyClass);
    trigged_btn.classList.add(successClass);
    window.setTimeout(function(){
      trigged_btn.classList.add(copyClass);
      trigged_btn.classList.remove(successClass);
    }, 2000);
  } catch (e){
    console.log(e);
  }
  el.focus();
  el.select();
}

export function renderDate(timestamp, format = 'month_day_year') {
  let d = new Date(timestamp);

  var day = d.getDate() + '';
  if (day.length === 1) day = '0' + day;

  var month = (d.getMonth() + 1) + '';
  if (month.length === 1) month = '0' + month;

  var year = d.getFullYear();

  if (format === 'day_month_year') {
    return [day, month, year].join('/');
  } else if (format === 'year_month_day') {
    return [year, month, day].join('/');
  } else if (format === 'relative') {
    const now = new Date();
    const diffInSeconds = Math.floor((now - d) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInMonths = Math.floor(diffInDays / 30);
    const diffInYears = Math.floor(diffInMonths / 12);

    if (diffInYears > 0) {
      return `${diffInYears} year${diffInYears === 1 ? '' : 's'} ago`;
    } else if (diffInMonths > 0) {
      return `${diffInMonths} month${diffInMonths === 1 ? '' : 's'} ago`;
    } else if (diffInDays > 0) {
      return `${diffInDays} day${diffInDays === 1 ? '' : 's'} ago`;
    } else if (diffInHours > 0) {
      return `${diffInHours} hour${diffInHours === 1 ? '' : 's'} ago`;
    } else if (diffInMinutes > 0) {
      return `${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'} ago`;
    } else {
      return 'Just now';
    }
  } else {
    return [month, day, year].join('/');
  }
}

export function _openPopup(url, windowName, windowFeatures, windowSide = "right") {
  return new Promise(function(resolve, reject) {
    if (windowSide == 'right'){
      var leftAdjust = '960';
    } else {
      var leftAdjust = '0';
    }
    var newWindow = window.open(url, windowName || '_blank', windowFeatures || 'height=500,width=700,left=' + leftAdjust);
    if (window.focus) {
      newWindow.focus();
    }
    return resolve({});
  });
}

export const defaultFonts = [
  "inherit", "Arial", "Arial Black", "Comic Sans MS",
  "Brush Script MT", "Courier New", "Georgia", "Garamond",
  "Helvetica", "Impact", "Lato", "Lucida Grande",
  "Microsoft Sans Serif", "Palatino", "Tahoma", "Trebuchet MS",
  "Times New Roman", "Verdana"
]

export function getQueryParams() {
  var queryParamsString = window.location.search.substr(1);
  return queryParamsString.split('&').reduce(function(acc, queryParam) {
    var tokens = queryParam.split('=');
    acc[tokens[0]] = decodeURIComponent(tokens[1]);
    return acc;
  }, {});
}

export function findParentByTagName(element, tagName) {
  let parent = element;
  while (parent !== null && parent.tagName !== tagName.toUpperCase()) {
    parent = parent.parentNode;
  }
  return parent;
}

export const waitForElement = async (selector) => {
  while ( document.querySelector(selector) === null) {
    await new Promise( (resolve) =>  requestAnimationFrame(resolve) )
  }
  return document.querySelector(selector);
};

export function setUrlParam(key, value) {
  if (window.history.pushState) {
    var newURL = new URL(window.location.href);
    newURL.searchParams.set(key, value);
    window.history.pushState({ path: newURL.href }, '', newURL.href);
  }
}

export function closeWidgetPopups(){
  const popupPages = document.querySelectorAll('.popup-page.active')
  popupPages.forEach((popup) => {
    popup.classList.remove("active");
  });
}
